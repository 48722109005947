import { Controller } from 'stimulus'
import { setOfferErrorMessage } from '../shared/thankyou_error_handle'
import { signUpValidateField, signUpvalidateFieldWhenSubmitting } from '../shared/signup'
import passAjaxInvalidAuthenticityToken from '../shared/authenticity_hanlder'

export default class extends Controller {
  static targets = ['ipEmailTxt', 'ipNumberTxt', 'ipPurposeTxt', 'ipPasswordTxt', 'countryNum', 'countryCode', 'errorMessage']

  connect() {
    this.initCountryOptions('Num', this.countryNumTarget)
    this.initCountryOptions('Code', this.countryCodeTarget)
  }

  showFloatingLabel(event) {
    event.target.nextSibling.classList.add('input-label--floating')
    this.removeErrorMessage(event)
  }

  validateField(event) {
    let target = event.target
    signUpValidateField(target)
  }

  showPassword() {
    if (document.querySelector('input#show_password').checked) {
      this.ipPasswordTxtTarget.type = 'password'
    } else {
      this.ipPasswordTxtTarget.type = 'text'
    }
  }

  validateFieldWhenSubmitting(event) {
    event.preventDefault()
    signUpvalidateFieldWhenSubmitting(this, this.handleSubmit.bind(this), true)
  }

  handleSubmit(data) {
    const self = this
    $('#overlay_loading').show()
    const errorMessage = this.errorMessageTarget

    $.ajax({
      url: '/ajax_v2/users/signup',
      type: 'POST',
      data: data,
      success: function (response) {
        if (response.user.UserID != 0 && response.user.Message != 'email-exist') {
          $('#modal-signup').hide()
          $('body').data('logged', true);

          $.ajax({
            url: '/ajax_v2/users/shortcutmember',
            type: 'POST',
            success: function(response) {
              if (response.result == true) {
                const offerInfo = $('#modal-signin').data('offer-info')
                const backOrderInfo = $('.backorder-form')[0]

                if (offerInfo) {
                  const dataJson = JSON.parse(offerInfo)
                  self.createOffer(dataJson)
                }

                if (backOrderInfo) {
                  const backOrderData = new FormData(backOrderInfo)

                  self.submitBackOrder(backOrderData)
                }
              } else {
                window.location.reload()
              }
            },
            error: function(response) { passAjaxInvalidAuthenticityToken(response) }
          })
        } else {
          $('#overlay_loading').hide()
          errorMessage.innerHTML = 'Email address already registered. Please sign in.';
          $('.send-button').addClass('inactive');
          $('.gcaptcha-error-message').css('display', 'block');
          grecaptcha.reset();
        }
      },
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    });
  }

  createOffer(dataJson) {
    const dataOffer = {
      referrer: sessionStorage.getItem('referrer'),
      ...dataJson
    }

    $.ajax({
      url: '/ajax_v2/transactions/offers',
      type: 'POST',
      data: dataOffer,
      success: function(data) {
        $('#overlay_loading').hide()
        setOfferErrorMessage(data)
        window.location.href = data.url
      },
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    });
  }

  submitBackOrder(backOrderData) {
    $.ajax({
      url: '/ajax_v2/backorders/create',
      data: backOrderData,
      type: 'POST',
      processData: false,
      contentType: false,
      success: function() {
        window.location.href = '/backorder2/thanks'
        $('#overlay_loading').hide()
      },
      error: function(response) {
        passAjaxInvalidAuthenticityToken(response)
      }
    })
  }

  removeErrorMessage(event) {
    event.target.classList.remove('error')
  }

  openSignInModal() {
    $('#modal-signup').hide()
    $('#modal-signin').modal();
  }

  initCountryOptions(type, target) {
    const optionLoaded = `optionCountry${type}Loaded`
    const countryOptions = `country${type}Options`
    const selectedCountryValue = `selectedCountry${type}Value`

    this[optionLoaded] = false
    this[countryOptions] = JSON.parse(target.dataset.options)
    this[selectedCountryValue] = this[countryOptions].find(option => option[1].toString() === target.dataset.selected)

    if (this[selectedCountryValue] !== undefined) {
      this.selectedCountryOption(this[selectedCountryValue][0], this[selectedCountryValue][1], target)
    }
  }

  loadCountryOptions(type, event) {
    const optionLoaded = `optionCountry${type}Loaded`
    const countryOptions = `country${type}Options`
    const selectedCountryValue = `selectedCountry${type}Value`
    const target = event.target
    if (this[optionLoaded]) return

    this[countryOptions].forEach(option => {
      if (this[selectedCountryValue] !== undefined && option[1] === this[selectedCountryValue][1]) return

      const optionElement = new Option(option[0], option[1])
      target.add(optionElement)
    })

    this[optionLoaded] = true
  }

  loadCountryNumOptions(event) {
    this.loadCountryOptions('Num', event)
  }

  loadCountryCodeOptions(event) {
    this.loadCountryOptions('Code', event)
  }

  selectedCountryOption(text, value, element) {
    const optionElement = new Option(text, value, true, true)
    element.add(optionElement)
  }
}
