import { ajax } from '@rails/ujs'
import LazyLoad from 'vanilla-lazyload'

export function getSellerInfo(wrapper) {
  ajax({
    url: wrapper.sellerInfoAreaTarget.dataset.carSellerUrl,
    type: 'GET',
    dataType: 'jsonp',
    success: function(response) {
      wrapper.sellerInfoAreaTarget.outerHTML = response.seller_info

      if (wrapper.hasSellerNameRatingTarget) {
        wrapper.sellerNameRatingTarget.outerHTML = response.seller_rating
      }
    },
    complete: function(){
      new LazyLoad({
        elements_selector: '.body__star, .body__star-l, .img-icon'
      })
    }
  })
}
