import { Controller } from 'stimulus'
import Accordion from 'accordion-js';
import { ajax } from '@rails/ujs'
import 'accordion-js/dist/accordion.min.css';
import { update_model_select_when_change_maker, update_model_select,
         fill_search_form_data, pull_maker_model_select_option, pull_select_option } from '../shared/data_loader';
import fetch_frame from '../shared/ranking_frame';
import loadModalAssist from '../shared/modal_assist'
import { setupImageSlider } from '../shared/image_slider';

export default class extends Controller {
  static targets = ['newArrivalWrapper', 'newArrivalLoading', 'newBrowsingHistoryWrapper',
                    'newBrowsingHistoryLoading', 'newBrowsingHistoryBlock', 'topRankingArea',
                    'popularRankingArea', 'localInfomation', 'mediaAndNews', 'newArrivalArea']

  connect() {
    const ac_l = new Accordion('.help__accordion-container-r', {
                                  duration: 300,
                                  showMultiple: false,
                                  beforeOpen: () => { ac_r.closeAll() }
                                });

    const ac_r = new Accordion('.help__accordion-container-l', {
                                  duration: 300,
                                  showMultiple: false,
                                  beforeOpen: () => { ac_l.closeAll() }
    });

    fetch_frame(this.localInfomationTarget, '/ajax_v2/fetch_local_infomation')
    if (this.hasMediaAndNewsTarget) { fetch_frame(this.mediaAndNewsTarget, '/ajax_v2/fetch_media_and_news') }
    this.loading_block_cars(this.newBrowsingHistoryWrapperTarget, this.newBrowsingHistoryLoadingTarget, this.newBrowsingHistoryBlockTarget, '/ajax_v2/browsing_history')
    this.loading_block_cars(this.newArrivalWrapperTarget, this.newArrivalLoadingTarget, this.newArrivalAreaTarget, '/ajax_v2/new_arrival_cars')
    fill_search_form_data((res) => {
      const filedLists = ['fid', 'jid', 'prcf', 'prct', 'sds', 'eds']
      pull_maker_model_select_option(res.maker_options, res.model_options)
      update_model_select()
      update_model_select_when_change_maker(true)
      pull_select_option(res, filedLists, true)
      this.saveValueModel()
      this.setValueMakeModel()
    })
    fetch_frame(this.topRankingAreaTarget, '/ajax_v2/fetch_car_ranking')
    if (this.hasPopularRankingAreaTarget) { fetch_frame(this.popularRankingAreaTarget, '/ajax_v2/fetch_popular_ranking') }
    loadModalAssist()
    setupImageSlider()
  }

  loading_block_cars(wrapper, elLoading, area, url) {
    ajax({
      url: url,
      type: 'GET',
      beforeSend: function() {
        elLoading.style.display = 'flex'
        return true;
      },
      success: function(response) {
        wrapper.innerHTML = response.body.outerHTML
      },
      error: function(error) {
        console.log(error)
      },
      complete: function() {
        const amountOfItems = wrapper.querySelectorAll('div.recommend-item').length
        area.classList.remove('d-none')
        if (amountOfItems == 0) {
          area.style.display = 'none'
          return;
        }
        setTimeout(() => {
          elLoading.style.display = 'none'
          wrapper.classList.remove('tmp--hide');
        }, 200);
      }
    })
  }

  showReset(e) {
    if (e.target.value) {
      $('.clear-icon').css('display', 'inline-block')
    } else {
      $('.clear-icon').hide()
    }
  }

  clearTextField(event) {
    event.preventDefault()
    $('.form-group--kw input').val('')
    $('.clear-icon').hide()
  }

  saveValueModel() {
    document.querySelector('.js-model-select').addEventListener('change', function(e) {
      $('#ett_select_model').val(e.target.value)
    })
  }

  setValueMakeModel() {
    if($('#ett_select_make').val() == 'undefined') return

    document.querySelector('.js-make-select').value = $('#ett_select_make').val() == 'undefined' ? '0' : $('#ett_select_make').val()
    update_model_select()
    document.querySelector('.js-model-select').value = $('#ett_select_model').val() == 'undefined' ? '0' : $('#ett_select_model').val()
  }
}
