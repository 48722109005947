import { ajax } from '@rails/ujs'
import { getDataURLSearchParams } from '../shared/url_search_param'
import { offerCarDetailParams } from './offer_params';
import { CURRENCY_REGEX } from './constants';

export function estPriceAlerts(targets, symbolAsterisk, country_number, serverKind = null, serverYear = null, serverBeforeYear = null) {
  let data = { country_number: country_number };

  ajax({
    url: '/ajax_v2/estimated_total_prices/alerts',
    type: 'get',
    dataType: 'jsonp',
    data: getDataURLSearchParams(data),
    success: function (response) {
      let pattern_alert = response.pattern_alert

      if (pattern_alert === undefined || Object.keys(response.pattern_alert).length == 0 || pattern_alert.text.length === 0) {
        targets.forEach((target) => target.innerText = '')
        if (serverKind) serverKind.value = ''
        if (serverYear) serverYear.value = ''
        if (serverBeforeYear) serverBeforeYear.value = ''
      } else {
        targets.forEach((target) => target.innerHTML = pattern_alert.text)
        if (serverKind) serverKind.value = pattern_alert.kind
        if (serverYear) serverYear.value = pattern_alert.year
        if (serverBeforeYear) serverBeforeYear.value = pattern_alert.before_year
      }

      symbolAsterisk.innerText = pattern_alert.show_asterisk ? '*' : ''
    }
  })
}

export function loadOptions(country_number, checkboxShippingTarget, checkboxInsuranceTarget, checkboxInspectionTarget) {
  let data = { country_number: country_number };

  ajax({
    url: '/ajax_v2/estimated_total_prices/options',
    type: 'get',
    dataType: 'jsonp',
    data: getDataURLSearchParams(data),
    success: function (response) {
      if (response.options === undefined) {
        toogleClassWithCondition(checkboxShippingTarget, 'd-none', false)
        toogleClassWithCondition(checkboxInsuranceTarget, 'd-none', false)
        toogleClassWithCondition(checkboxInspectionTarget, 'd-none', false)
      } else {
        toogleClassWithCondition(checkboxShippingTarget, 'd-none', response.options.shipping)
        toogleClassWithCondition(checkboxInsuranceTarget, 'd-none', response.options.insurance)
        toogleClassWithCondition(checkboxInspectionTarget, 'd-none', response.options.pre_ship_inspection)
      }
    }
  })
}

export function execEstTotalPrice(formTarget, car_ids, animate = false, callback) {

  let dataParsed = {
    car_ids: JSON.stringify(car_ids),
    ...offerCarDetailParams(formTarget)
  }

  let icons = document.querySelectorAll('.group__estimated .ico.icon-cached')
  let totalPriceEls = document.querySelectorAll('.group__estimated span.total__price')

  ajax({
    url: '/ajax_v2/estimated_total_prices/calculates',
    type: 'get',
    dataType: 'jsonp',
    data: getDataURLSearchParams(dataParsed),
    beforeSend: function() {
      if (animate) {
        icons.forEach(icon => icon.classList.add('rotate'))
        totalPriceEls.forEach(icon => icon.innerText = '')
      }
      return true;
    },
    success: function (response) {
      setTimeout(() => callback(response), 250);
    }
  })
}

const toogleClassWithCondition = (target, klass, condition) => {
  condition ? target.classList.remove(klass) : target.classList.add(klass)
}

export function displayTotalPrice(price) {
  if (price === undefined || !price.isSuccess || price.totalPriceUSD === 0 || price.totalPriceView === undefined ) {
    return 'Ask'
  } else {
    return price.totalPriceView
  }
}

export function displayBNPLPrice(price) {
  const priceView = displayTotalPrice(price)
  const match = CURRENCY_REGEX.exec(priceView)
  if (match) {
    let bnplPrice = (parseFloat(match.groups.amount.replace(/,/g, '')) / 2)
    bnplPrice = match.groups.currency2 === 'Lakhs'
      ? Math.round(bnplPrice * 100) / 100
      : Math.round(bnplPrice)

    return `${match.groups.currency1}${bnplPrice.toLocaleString('en')}${match.groups.currency2}`
  }
  return priceView
}

export function changeTradetpots(tradetpotsId, shippingId, insuranceId, inspectionId) {
  const shipping = document.querySelector(`input#${shippingId}`).checked
  const insurance = document.querySelector(`input#${insuranceId}`).checked
  const inspection = document.querySelector(`input#${inspectionId}`).checked

  $(`#${tradetpotsId}`).val(JSON.stringify({shipping, insurance, inspection}))
}

export function pickPriceFormData(init, selectCountryId, currentPriceFormData) {
  let priceFormData

  if (init && $(`#${selectCountryId}`).val() != 'undefined') {
    priceFormData = JSON.parse($(`#${selectCountryId}`).val())
  } else {
    priceFormData = currentPriceFormData
    $(`#${selectCountryId}`).val(JSON.stringify(currentPriceFormData))
  }

  return priceFormData
}

export function checkCountryPreshipRequired(country) {
  const countryPreshipRequired = JSON.parse(document.querySelector('.js--countries-require-preship').dataset.countries)
  const selectedCountry = Number(country)

  return countryPreshipRequired.includes(selectedCountry)
}

export function checkCountryShippingDefault(country){
  const countryShippingDefault = JSON.parse(document.querySelector('.js--countries-default-shipping').dataset.countries)
  const selectedCountry = Number(country)

  return countryShippingDefault.includes(selectedCountry)
}
