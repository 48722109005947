import { Controller } from 'stimulus'
import SlideImage from '../shared/slide_images'
import { ajax } from '@rails/ujs'
import Accordion from 'accordion-js'
import 'accordion-js/dist/accordion.min.css'
import { estPriceAlerts, loadOptions, execEstTotalPrice, displayTotalPrice,
         changeTradetpots, pickPriceFormData, checkCountryPreshipRequired,
         displayBNPLPrice, checkCountryShippingDefault } from '../shared/est_price_module'
import favorite_action from '../../components/favotite_action'
import { handleSubmitContactSeller } from '../shared/validate_registration_year_module'
import { setOfferErrorMessage } from '../shared/thankyou_error_handle'
import { isUserLogin } from '../shared/users_api'
import { offerCarDetailParams } from '../shared/offer_params'
import { getDataURLSearchParams } from '../shared/url_search_param'
import { getSellerInfo } from '../shared/seller_info'
import { fetchInquiriesCounter } from '../shared/inquiry_car'
import passAjaxInvalidAuthenticityToken from '../shared/authenticity_hanlder'
import { EMAIL_REGEX, CONFIRM_MSG } from '../shared/constants'
import loadModalAssist from '../shared/modal_assist'
import { activeTab, enableFinanceTab } from '../shared/tab'
import { TARGET_COUNTRY_CODE } from '../shared/constants'

window.estPriceAccordion = {}
export default class extends Controller {
  static targets = ['boxSelectCountry', 'elEstimated', 'elNotEstimated', 'btnCalculate', 'estPriceForm',
                    'tooltipBlock', 'btnChangeDest', 'countrySelectOptions', 'portSelectOptions', 'btnCalculate',
                    'symbolAsterisk', 'insuranceAlert', 'checkboxShipping', 'checkboxInsurance', 'checkboxInspection',
                    'incoterm', 'nearestPort', 'displayTotalPriceEl', 'serverKind', 'serverYear', 'serverBeforeYear',
                    'cbOffer_1', 'cbOffer_2', 'cbOffer_3', 'textareaCommentI', 'cbReceivePromotion', 'catalogArea',
                    'sellerInfoArea', 'sellerNameRating', 'inquiriesCounter', 'modalSignup', 'modalSignin',
                    'displayBNPLPriceEl', 'tabEl']

  static values = {
    itemIds: Array
  }

  connect() {
    SlideImage()
    if (this.hasEstPriceFormTarget) {
      this.btnChangeDestTarget.addEventListener('mouseover', () => this.closeTooltip())
      document.addEventListener('click', () => this.closeTooltip())
      estPriceAccordion = new Accordion(".accordion-block", {
        duration: 300,
        onOpen: (currElement) => {
          $('#ac_panel').val('true')
          currElement.querySelector('.ac-panel').style.overflow = 'unset'
        },
        beforeClose: (currElement) => {
          $('#ac_panel').val('false')
          this.closeTooltip()
          currElement.querySelector('.ac-panel').style.overflow = 'hidden'
        }
      })
      window.addEventListener('load', () => {
        setTimeout(() => {
          this.toggleChooseCountryBox()
          this.implementEstimateTotalPrice(true)
          this.loadPortInfo(this.countrySelectOptionsTarget, true)
          this.showNearestPort()
        }, 100)
      })
      favorite_action(document, true)
      fetchInquiriesCounter(this.inquiriesCounterTarget, () => {
        document.querySelector('.ask-price-bottom').classList.add('appearance')
      })
      this.fetchModals()
    }
    setTimeout(() => this.loadCatalog(), 100)
    getSellerInfo(this)
    this.handleConfirmInquire()
    loadModalAssist()
    this.imageZoom()
    this.defaultTab()
  }

  scrollToDiv(e) {
    const target = document.querySelector(`#${e.currentTarget.dataset.idTarget}`)
    target.scrollIntoView({ behavior: 'smooth' })
  }

  toggleChooseCountryBox() {
    const isPanelOpen = $('#ac_panel').val() != 'undefined' ?
      $('#ac_panel').val() == 'true' : this.boxSelectCountryTarget.dataset.estimated == undefined

    if (isPanelOpen) {
      estPriceAccordion.openAll()
    } else {
      estPriceAccordion.close(0)
    }
  }

  calculateEstTotalPrice(e) {
    const elEstimatedClasslist = this.elEstimatedTarget.classList
    const elNotEstimatedClasslist = this.elNotEstimatedTarget.classList
    elEstimatedClasslist.remove('d-none')
    elEstimatedClasslist.add('d-block')

    elNotEstimatedClasslist.remove('d-block')
    elNotEstimatedClasslist.add('d-none')
    if (this.checkboxInspectionTarget.classList.contains('d-none') || document.querySelector('input#dt_checkbox_inspection').checked) {
      estPriceAccordion.close(0)
    }
    this.saveCookieEstPrice()
  }

  implementEstimateTotalPrice(init = false) {
    const priceFormData = pickPriceFormData(init, 'ett_country_port', this.estPriceFormData())

    execEstTotalPrice(priceFormData, this.itemIdsValue, false, (response) => {
      if (Object.keys(response.total_price_items).length == 0) return;

      this.displayTotalPriceElTarget.innerText = displayTotalPrice(response.total_price_items[0])
      this.displayBNPLPriceElTarget.innerText = displayBNPLPrice(response.total_price_items[0])
    })
  }

  showNearestPort() {
    if ($('#ett_incoterm_port').val() == 'undefined') return

    const { icoterm, port } = JSON.parse($('#ett_incoterm_port').val())
    this.incotermTarget.innerText = icoterm
    this.nearestPortTarget.innerText = port

    this.elEstimatedTarget.classList.remove('d-none')
    this.elEstimatedTarget.classList.add('d-block')
    this.elNotEstimatedTarget.classList.remove('d-block')
    this.elNotEstimatedTarget.classList.add('d-none')
  }

  estPriceFormData() {
    let form = this.estPriceFormTarget

    return {
      country_number: form.querySelector('select#sl_country').value,
      port_id: form.querySelector('select#sl_port').value || form.querySelector('select#sl_port').dataset.portSelected,
      shipping: !this.checkboxShippingTarget.classList.contains('d-none') && !form.querySelector('input#dt_checkbox_shipping').disabled
                && form.querySelector('input#dt_checkbox_shipping').checked ? 1 : 0,
      insurance: !this.checkboxInsuranceTarget.classList.contains('d-none') && !form.querySelector('input#dt_checkbox_insurance').disabled
                 && form.querySelector('input#dt_checkbox_insurance').checked ? 1 : 0,
      inspection: checkCountryPreshipRequired(this.countrySelectOptionsTarget.value) || !this.checkboxInspectionTarget.classList.contains('d-none')
                  && !form.querySelector('input#dt_checkbox_inspection').disabled && form.querySelector('input#dt_checkbox_inspection').checked ? 1 : 0,
      show_shipping: $('input#dt_checkbox_shipping').closest('li').is(':visible') ? 1 : 0
    }
  }

  openTooltip(e) {
    e.stopPropagation()

    const currentTarget = e.currentTarget
    const tooltip = currentTarget.nextSibling
    this.closeTooltip()
    tooltip.classList.add('appearance')
  }

  closeTooltip() {
    this.tooltipBlockTargets.forEach((tt) => tt.classList.remove('appearance'))
  }

  toggleCheckbox(e) {
    let selectedValue = e.currentTarget.value
    const cbShp  = document.querySelector('input#dt_checkbox_shipping')
    const cbInsu = document.querySelector('input#dt_checkbox_insurance')
    const cbInsp = document.querySelector('input#dt_checkbox_inspection')
    $('#ett_select_port').val(selectedValue)

    if (selectedValue == 0) {
      cbShp.disabled  = true
      cbInsu.disabled = true
      cbInsp.disabled = true
      this.btnCalculateTarget.disabled = true
    } else {
      cbShp.disabled  = false
      cbInsu.disabled = false
      cbInsp.disabled = checkCountryPreshipRequired(this.countrySelectOptionsTarget.value)
      this.btnCalculateTarget.disabled = false
    }

    let data = this.estPriceFormData()
    if ( data['port_id'] === '0' ) return

    data['just_update_itemdetailshipestimate'] = true

    ajax({
      url: '/ajax_v2/estimated_total_prices/cookies',
      type: 'put',
      dataType: 'jsonp',
      data: getDataURLSearchParams(data),
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    })
  }

  loadPortInfo(e, init = false) {
    const self = this
    let data
    const ettSelectCountry = $('#ett_select_country').val()
    const currentTarget = e.target === undefined ? e : e.target
    if (init && ettSelectCountry != 'undefined') {
      data = { country_number: ettSelectCountry }
      this.countrySelectOptionsTarget.value = ettSelectCountry
    } else {
      data = { country_number: currentTarget.value }
      $('#ett_select_country').val(currentTarget.value)
    }
    const selectedValue = $('#ett_select_port').val() != 'undefined' ? $('#ett_select_port').val() : this.portSelectOptionsTarget.dataset.portSelected

    ajax({
      url: '/ajax_v2/estimated_total_prices/nearest_ports',
      type: 'get',
      dataType: 'jsonp',
      data: getDataURLSearchParams(data),
      success: function (response) {
        self.portSelectOptionsTarget.innerHTML = ''
        self.portSelectOptionsTarget.add(new Option('Please Select', 0))
        self.btnCalculateTarget.disabled = true

        if (response.ports === undefined || response.ports.length == 0) {
          self.portSelectOptionsTarget.disabled = true
        } else {
          self.portSelectOptionsTarget.disabled = false
          response.ports.forEach(port =>{
            let option = new Option(port[1], port[0])
            self.portSelectOptionsTarget.add(option)
          })

          const selectedValueIsCollect = response.ports.find((portChild) => parseInt(selectedValue) == portChild[0])

          if (selectedValueIsCollect && init) {
            let optionSelected = self.portSelectOptionsTarget.querySelector(`option[value="${selectedValue}"]`)

            optionSelected.selected = true
            self.btnCalculateTarget.disabled = false

            document.querySelector('input#dt_checkbox_shipping').disabled = false
            document.querySelector('input#dt_checkbox_insurance').disabled = false

            document.querySelector('input#dt_checkbox_shipping').checked = checkCountryShippingDefault(self.countrySelectOptionsTarget.value)

            if (checkCountryPreshipRequired(self.countrySelectOptionsTarget.value)) {
              document.querySelector('input#dt_checkbox_inspection').checked = true
              document.querySelector('input#dt_checkbox_inspection').disabled = true
            } else {
              document.querySelector('input#dt_checkbox_inspection').disabled = false
            }
          } else {
            document.querySelector('input#dt_checkbox_shipping').disabled = true
            document.querySelector('input#dt_checkbox_insurance').disabled = true
            document.querySelector('input#dt_checkbox_inspection').disabled = true
            $('#ett_select_port').val('0')
          }
        }
      }
    })

    estPriceAlerts(this.insuranceAlertTargets, this.symbolAsteriskTarget, currentTarget.value,
      this.serverKindTarget, this.serverYearTarget, this.serverBeforeYearTarget
    )

    loadOptions(currentTarget.value, self.checkboxShippingTarget, self.checkboxInsuranceTarget, self.checkboxInspectionTarget)
    if (init) {
      if ($('#ett_tradetpots').val() == 'undefined') return

      const { shipping, insurance, inspection } = JSON.parse($('#ett_tradetpots').val())
      document.querySelector('input#dt_checkbox_shipping').checked = shipping
      document.querySelector('input#dt_checkbox_insurance').checked = insurance
      document.querySelector('input#dt_checkbox_inspection').checked = inspection
    } else {
      document.querySelector('input#dt_checkbox_shipping').checked = checkCountryShippingDefault(self.countrySelectOptionsTarget.value)
      document.querySelector('input#dt_checkbox_insurance').checked = false
      document.querySelector('input#dt_checkbox_inspection').checked = true
      this.handleChangeTradetpots()
    }
  }

  handleChangeTradetpots() {
    changeTradetpots('ett_tradetpots', 'dt_checkbox_shipping', 'dt_checkbox_insurance', 'dt_checkbox_inspection')
  }

  saveCookieEstPrice() {
    const self = this
    let form = this.estPriceFormTarget
    let data = this.estPriceFormData()

    ajax({
      url: '/ajax_v2/estimated_total_prices/cookies',
      type: 'put',
      dataType: 'jsonp',
      data: getDataURLSearchParams(data),
      success: (response) => {
        let destination = form.querySelector('select#sl_port').selectedOptions[0].innerText
        const incotermPort = { icoterm: response.incoterms, port: destination }

        self.incotermTargets.map(target => target.innerText = incotermPort['icoterm'])
        self.nearestPortTargets.map(target => target.innerText = incotermPort['port'])
        $('#ett_incoterm_port').val(JSON.stringify(incotermPort))
        this.implementEstimateTotalPrice()
      },
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    })
  }

  handleOfferWithUserAuth(e) {
    e.stopPropagation()

    isUserLogin().then((isUserSignedIn) => {
      if (isUserSignedIn) {
        this.execInquiry(e)
      } else {
        this.openSignInModal()
      }
    })
  }

  openSignInModal() {
    $('#modal-signin, #modal-signup').data('offer-info', JSON.stringify(this.offerStockParams()))

    $('#modal-signup').hide()
    $('#modal-signin').modal()
  }

  openContactSellerModal(e) {
    e.stopPropagation()
    $("#modal-contact-seller").modal()
  }

  addTextIntoTextArea(e) {
    this.textareaCommentITarget.innerText = ''
    this.textareaCommentITarget.value = ''

    let text = ''
    let checkedCounter = 0
    if (this.cbOffer_1Target.checked) { text += "I want to negotiate the best price\n"; checkedCounter += 1 }
    if (this.cbOffer_2Target.checked) { text += "I want to know the shipping schedule\n"; checkedCounter += 1 }
    if (this.cbOffer_3Target.checked) { text += "I want to know about the condition of the car\n"; checkedCounter += 1 }
    if (text == '') {
      this.textareaCommentITarget.textContent = ''
      this.textareaCommentITarget.value = ''
    } else {
      checkedCounter === 3 ? this.textareaCommentITarget.rows = 3 : this.textareaCommentITarget.rows = 2
      this.textareaCommentITarget.textContent = text
      this.textareaCommentITarget.value = text
    }
  }

  execInquiry(e) {
    const messageContent = this.textareaCommentITarget.value
    if (messageContent.match(EMAIL_REGEX) && !confirm(CONFIRM_MSG)) return

    if (handleSubmitContactSeller(e)) this.handleOfferStock()
  }

  handleConfirmInquire() {
    const self = this

    $("#modal-check-year .inquire-btn").on("click", function() {
      $("#modal-check-year").modal("hide")
      self.handleOfferStock()
    })
  }

  handleOfferStock() {
    $('#overlay_loading').show()
    $.ajax({
      url: "/ajax_v2/transactions/offers",
      type: "POST",
      data: this.offerStockParams(),
      success: function(data) {
        $('#overlay_loading').hide()
        setOfferErrorMessage(data)
        window.location.href = data.url
      },
      error: function(response) { passAjaxInvalidAuthenticityToken(response) }
    })
  }

  offerStockParams() {return {
      message: $(this.textareaCommentITarget).val(),
      is_bnpl: $('#finance_content').is(':visible'),
      receive_promotion: $(this.cbReceivePromotionTarget).is(":checked"),
      item_id: $(".car-detail-wrapper").data('car-item-ids-value')[0],
      seller_id: $('.car-detail-wrapper').data('car-seller-id'),
      referrer: sessionStorage.getItem("referrer"),
      ...offerCarDetailParams(this.estPriceFormData())
    }
  }

  printPage() {
    window.print()
  }

  loadCatalog() {
    const catalogArea = this.catalogAreaTarget
    const url = this.catalogAreaTarget.dataset.carCatalogUrl

    ajax({
      url: url,
      type: 'GET',
      success: function(response) {
        if (response.body.innerHTML) {
          catalogArea.outerHTML = response.body.outerHTML
          $('li.car__info-nav-item[data-id-target="catalog"]').removeClass('d-none')
        }
      }
    })
  }

  fetchModals() {
    const modalSignup = this.modalSignupTarget
    const modalSignin = this.modalSigninTarget

    ajax({
      url: modalSignup.dataset.url,
      type: 'GET',
      success: function (response) {
        modalSignup.outerHTML = response.modal_signup
        modalSignin.outerHTML = response.modal_signin

        $(".tcv-modal .icon-close").on("click", function() {
          $(".tcv-modal").modal("hide");
        })
      }
    })
  }

  imageZoom = () => {
    let img = this.element.querySelector('.mainpic')
    let result = this.element.querySelector('.img-zoom-result')
    let lens
    const zoomFactor = 2

    const createLens = () => {
      lens = document.createElement("DIV")
      lens.setAttribute("class", "img-zoom-lens")
      img.parentElement.insertBefore(lens, img)
    }

    const updateBackgroundImage = () => {
      result.style.backgroundImage = "url('" + img.src + "')"
    }

    const calculateZoomRatio = () => {
      result.style.backgroundSize = (img.width * zoomFactor) + "px " + (img.height * zoomFactor) + "px"
    }

    const moveLens = (e) => {
      e.preventDefault()
      const pos = getCursorPos(e)
      let x = pos.x - (lens.offsetWidth / 2)
      let y = pos.y - (lens.offsetHeight / 2)
      x = Math.min(Math.max(x, 0), img.width - lens.offsetWidth)
      y = Math.min(Math.max(y, 0), img.height - lens.offsetHeight)
      lens.style.left = x + "px"
      lens.style.top = y + "px"
      result.style.backgroundPosition = "-" + (x * zoomFactor) + "px -" + (y * zoomFactor) + "px"
    }

    const getCursorPos = (e) => {
      const a = img.getBoundingClientRect()
      let x = e.pageX - a.left - window.scrollX
      let y = e.pageY - a.top - window.scrollY
      return { x: x, y: y }
    }

    const showZoomOnMouseEnter = () => {
      updateBackgroundImage()
      createLens()
      calculateZoomRatio()
      lens.addEventListener("mousemove", moveLens)
      img.addEventListener("mousemove", moveLens)
      lens.addEventListener("touchmove", moveLens)
      img.addEventListener("touchmove", moveLens)
      result.classList.remove('d-none')
    }

    const hideZoomOnMouseLeave = () => {
      result.classList.add('d-none')
      if (lens) lens.remove()
    }

    img.addEventListener("mouseenter", showZoomOnMouseEnter)
    img.addEventListener("mouseleave", hideZoomOnMouseLeave)

    const observer = new MutationObserver(() => {
      img = this.element.querySelector('.mainpic')
      updateBackgroundImage()
    })
    observer.observe(img, { attributes: true, attributeFilter: ['src'] })
  };

  showTab(e) {
    e.preventDefault()
    activeTab(e.target)
  }

  defaultTab() {
    const cashTab = document.querySelector('#cash_tab')
    if (cashTab.dataset.tabDefault === 'true') {
      activeTab(cashTab)
    } else {
      this.tabElTargets.map(el => el.hidden = true)
      $('.used__detail-price-area').addClass('used__detail-price-area--normal')
      $('.tab-content__block[data-tab-content="cash"]').addClass('d-block')
    }
  }

  enableFinanceTab() {
    // the user has never selected Kenya/Zambia/Uganda before or now
    if (this.tabElTarget.hidden && !TARGET_COUNTRY_CODE.includes(parseInt(this.countrySelectOptionsTarget.value))) return

    // the user has never selected Kenya/Zambia/Uganda before but now selected
    if (this.tabElTarget.hidden) {
      $('.used__detail-price-area').removeClass('used__detail-price-area--normal')
      this.tabElTargets.map(el => el.hidden = false)
      $('.tab-content__block[data-tab-content="cash"]').removeClass('d-block')
      activeTab(this.tabElTarget)
    }
    enableFinanceTab()
  }
}
